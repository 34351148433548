import './Navbar.css';
import Logo from '../img/logoFinal.fw.png'


function Navbar() {
    return (
        <div id="top-header">
            <img src={Logo} alt="" srcset="" id="logo" />
            <div id="theme">
                <svg class="" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                    onclick="mudarBackground()">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18.1818V1.81818C5.48131 1.81818 1.81818 5.48131 1.81818 10C1.81818 14.5187 5.48131 18.1818 10 18.1818Z"
                        fill="white"></path>
                </svg>
                <a class="" href="#" style={{ color: 'white', textDecoration: 'none' }}> A− </a>
                <a class="" href="#" style={{ color: 'white', textDecoration: 'none' }}> A+ </a>
            </div>
        </div>
    );
}
export default Navbar;