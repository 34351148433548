import './App.css';
import Navbar from './components/Navbar.js';
import Header from './components/Header.js';
import Card from './components/Card.js';
import paramount from './components/img/logo-paramount.png';
import hbomax from './components/img/hbomax.png';


function App() {
  return (
    <>
      <Navbar />
      <Header />
      <main>
        <Card
          velocidade='300 Mega'
          anterior='127,36'
          atual='98,50'
          displayBandNews={{ display: 'none' }}
          displaybandSports={{ display: 'none' }}
          displayGamesClub={{ display: '' }}
          displaysegDigital={{ display: '' }}
          displayaudioBooks={{ display: '' }}
          displaybancaVirtual={{ display: '' }}
          displayBabel={{ display: '' }}
          displayLooke={{ display: 'none' }}
        />

        <Card
          velocidade='500 Mega'
          anterior='138,94'
          atual='101,50'
          assinatura='Assinaturas incluídas'
          paramount={<img src={paramount} alt="" srcset="" />}
          displayGamesClub={{ display: 'none' }}
          displayBandNews={{ display: 'none' }}
          displaybandSports={{ display: 'none' }}          
          displaysegDigital={{ display: 'none' }}
          displayaudioBooks={{ display: '' }}
          displaybancaVirtual={{ display: '' }}
          displayBabel={{ display: '' }}
          displayLooke={{ display: 'none' }}
        />

        <Card
          velocidade='1 Giga'
          anterior='208,41'
          atual='135,50'
          assinatura='Assinaturas incluídas'
          paramount={<img src={paramount} alt="" srcset="" />}
          displayBandNews={{ display: '' }}
          displaybandSports={{ display: '' }}
          displayGamesClub={{ display: '' }}
          displaysegDigital={{ display: '' }}
          displayaudioBooks={{ display: '' }}
          displaybancaVirtual={{ display: '' }}
          displayBabel={{ display: '' }}
          displayLooke={{ display: 'none' }}
        />

        <Card
          velocidade='1 Giga HBO'
          anterior='444,99'
          atual='244,90'
          assinatura='Assinaturas incluídas'
          hbomax={<img src={hbomax} alt="" srcset="" />}
          paramount={<img src={paramount} alt="" srcset="" />}
          displayBandNews={{ display: '' }}
          displaybandSports={{ display: '' }}
          displayGamesClub={{ display: '' }}
          displaysegDigital={{ display: '' }}
          displayaudioBooks={{ display: '' }}
          displaybancaVirtual={{ display: '' }}
          displayBabel={{ display: '' }}
          displayLooke={{ display: '' }}
        />

      </main>
      <footer>
        <p style={{ textAlign: 'center', fontSize: '12px' }}>Descontos válidos por 12 meses pagando no débito automático.</p>
      </footer>
    </>
  );
}

export default App;
