import './Card.css';
import gamesClub from './img/ico-games-club.png';
import segDigital from './img/ico-segurança-digital.svg';
import audioBooks from './img/ico-audiobooks.png';
import bancaVirtual from './img/ico-banca-virtual.png';
import babel from './img/ico-babel.svg';
import bandNews from './img/ico-band-news.svg';
import bandSports from './img/ico-band-sports.svg';
import looke from './img/ico-looke-v1.png';

function Card(props) {
    return (
        <div className="product-container">
            <div className="text-container">
                <div className="card-header">
                    <h6>Modem de Internet Wi-fi grátis</h6>
                    <h1 style={{ marginTop: '0', marginBottom: '0' }}>{props.velocidade}</h1>
                </div>
                <div className="card-price">
                    <h4>De R$ {props.anterior} / mês</h4>
                    <h3>Por R$ {props.atual} / mês</h3>
                </div>
            </div>
            <div className="card-buttons">
                <a className="contratar-wpp-btn" href="#">Contratar via Whatsapp &gt;</a>
            </div>
            <div className="assinatura">
                <h4>{props.assinatura}</h4>
                <div className="sign-container">
                    {props.paramount}
                    {props.hbomax}
                </div>
            </div>
            <div className="conteudo-online">
                <h5>Conteúdo online</h5>
                <div className="icons-container">
                    <img src={bandNews} alt="" srcset="" className="icons" style={props.displayBandNews} />
                    <img src={bandSports} alt="" srcset="" className="icons" style={props.displaybandSports} />
                    <img src={gamesClub} alt="" srcset="" className="icons" style={props.displayGamesClub} />
                    <img src={segDigital} alt="" srcset="" className="icons" style={props.displaysegDigital}/>
                    <img src={audioBooks} alt="" srcset="" className="icons" style={props.displayaudioBooks} />
                    <img src={bancaVirtual} alt="" srcset="" className="icons" style={props.displaybancaVirtual} />
                    <img src={babel} alt="" srcset="" className="icons" style={props.displayBabel} />
                    <img src={looke} alt="" srcset="" className="icons" style={props.displayLooke} />
                </div>

            </div>
        </div >
    );
}

export default Card;
